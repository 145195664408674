import { defineMessages } from 'react-intl';

export default defineMessages({
  lastOnlineLabel: {
    id: 'User.UserAccountHeader.lastOnlineLabel',
    defaultMessage: 'Last online: { time }',
  },
  roleLabel: {
    id: 'User.UserAccountHeader.roleLabel',
    defaultMessage: 'Role: { role }',
  },
  createdAtLabel: {
    id: 'User.UserAccountHeader.createdAtLabel',
    defaultMessage: 'Registered on { date }',
  },
  depositLabel: {
    id: 'User.UserAccountHeader.depositLabel',
    defaultMessage: 'Total Deposit',
  },
  withdrawalLabel: {
    id: 'User.UserAccountHeader.withdrawalLabel',
    defaultMessage: 'Total Withdrawal',
  },
  totalDepositAndWithdrawLabel: {
    id: 'User.UserAccountHeader.totalDepositAndWithdrawLabel',
    defaultMessage: 'Total Deposit & Withdraw',
  },
});
