import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

interface SnapshotTableProps {
  side: string;
  snapshotData: {
    price: number;
    size: number;
  }[];
}

const SnapshotTable = (props: SnapshotTableProps): JSX.Element => {
  const { side, snapshotData } = props;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant={'h2'}>
                <FormattedMessage
                  {...messages.availableData}
                  values={{ side }}
                />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant={'h3'}>
                <FormattedMessage {...messages.price} />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant={'h3'}>
                <FormattedMessage {...messages.size} />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {snapshotData.map(({ price, size }, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <Typography variant={'body1'}>{price}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body1'}>{size}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SnapshotTable;
