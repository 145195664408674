import {
  Box,
  DataGridPremiumProps,
  SimpleDataGridPremium,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import useUpdateDateRangeConfig from './useUpdateDateRangeConfig';
import useBrandId from '../../../common/hooks/useBrandId';
import useFetchDateRange from './useFetchDateRange';
import { useCallback, useContext, useMemo } from 'react';
import {
  GridCellEditCommitParams,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid';
import useSanitiseRows from './useSanitiseRows';
import { useIntl } from 'react-intl';
import messages from './messages';
import { isInstanceOfGridCellEditCommitParams } from '../../../common/isInstanceOf';
import IsDisabledCell from './IsDisabledCell';

interface DateRangeProps
  extends Omit<DataGridPremiumProps, 'columns' | 'rows'> {
  playerId?: string;
}

const DateRangeSettings: React.FC<DateRangeProps> = (props) => {
  const { playerId } = props;

  const brandId = useBrandId();

  const {
    isLoading,
    data: responseData,
    refetch,
  } = useFetchDateRange(playerId);
  const { data: rows } = responseData || {};

  const sanitisedRows = useSanitiseRows(rows || []);
  const rowHeight = 40;

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const { mutateAsync: updateMarketTypeConfig, isLoading: isSubmitting } =
    useUpdateDateRangeConfig();

  const defaultColumnParams: Partial<GridEnrichedColDef> = useMemo(() => {
    return {
      editable: false,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
    };
  }, []);

  const columns: GridColumns = useMemo(() => {
    return [
      {
        field: 'id',
        hide: true,
      },
      {
        ...defaultColumnParams,
        field: 'ref',
        headerName: intl.formatMessage(messages.dateRange),
      },
      {
        ...defaultColumnParams,
        field: 'isDisabled',
        headerName: intl.formatMessage(messages.disable),
        renderCell: (params) => (
          <IsDisabledCell {...params} disabled={isSubmitting} />
        ),
        renderEditCell: (params) => (
          <IsDisabledCell {...params} disabled={isSubmitting} />
        ),
      },
    ];
  }, [intl, defaultColumnParams, isSubmitting]);

  const onRowEdit = useCallback(
    async (params: GridCellEditCommitParams) => {
      if (!isInstanceOfGridCellEditCommitParams(params)) {
        return;
      }
      const { id, field, value } = params;

      if (field === 'isDisabled') {
        const isDisabled = value as boolean;
        try {
          await updateMarketTypeConfig({
            brandId,
            playerId,
            isDisabled,
            ref: id.toString().toLowerCase(),
          });

          setAlert({
            severity: 'success',
            show: true,
            message: intl.formatMessage(messages.successUpdate),
          });
        } catch (error) {
          setAlert({
            severity: 'error',
            show: true,
            message: intl.formatMessage(messages.errorUpdate),
          });
        }

        refetch();
      }
    },
    [brandId, intl, playerId, refetch, setAlert, updateMarketTypeConfig],
  );

  return (
    <>
      <Box height={1} display="flex">
        <SimpleDataGridPremium
          columns={columns}
          rows={sanitisedRows || []}
          rowHeight={rowHeight}
          autoHeight
          disableSelectionOnClick
          onCellEditCommit={onRowEdit}
          loading={isLoading || isSubmitting}
        />
      </Box>
    </>
  );
};

export default DateRangeSettings;
