import { useCallback } from 'react';
import { useFirebaseApp } from 'reactfire';
import { GridRowData } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DEFAULT_REGION } from '../constants';

interface UseFundPassingBetsParams {
  uid: string;
  brandId: string;
}

interface GetFundPassingBetsResult {
  data: {
    fundPassingBetsResponse: GridRowData[];
  };
}

const useGetFundPassingBets = (
  params: UseFundPassingBetsParams,
): typeof queryResult => {
  const { uid, brandId } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getFundPassingBetsFunction = httpsCallable<
    UseFundPassingBetsParams,
    GetFundPassingBetsResult
  >(functions, 'back-exchangeBet-callGetFundPassingBets');

  const getFundPassingBets = useCallback(async () => {
    const response = await getFundPassingBetsFunction({ uid, brandId });

    const data = response.data.data.fundPassingBetsResponse;
    const count = data.length;

    return { data, count };
  }, [getFundPassingBetsFunction, uid, brandId]);

  const queryResult = useQuery<
    { data: GridRowData[]; count: number | null },
    Error
  >(['fundPassingBets', uid], getFundPassingBets, {
    keepPreviousData: true,
    onError: (error) => error,
  });

  return queryResult;
};

export default useGetFundPassingBets;
