import { v4 } from 'uuid';
import { TelegramSettings } from './TelegramSettingsForm';

interface DBsettings {
  id: string;
  chat_id: string;
  min_range: number;
  max_range: number;
  brand_id?: string;
  isValid?: boolean;
}

export const formatForForm = (set: DBsettings): TelegramSettings => {
  return {
    id: set.id,
    chatId: set.chat_id,
    minRange: set.min_range,
    maxRange: set.max_range,
  };
};

const formatForDB = (set: TelegramSettings, brandId: string): DBsettings => {
  return {
    id: set.id === '' ? v4() : set.id,
    chat_id: set.chatId.trim(),
    min_range: Number(set.minRange),
    max_range: Number(set.maxRange),
    brand_id: brandId,
    isValid: true,
  };
};

const validateMinMaxRange = (min: number, max: number): boolean => {
  if (min <= 0 || max <= 0) return false;
  if (min >= max) return false;
  return true;
};

const validateAndFormat = (
  set: TelegramSettings,
  brandId: string,
): DBsettings => {
  const formattedSettings = formatForDB(set, brandId);
  const { chat_id: chatId, min_range: min, max_range: max } = formattedSettings;

  if (chatId !== '') {
    const isValid = validateMinMaxRange(min, max);
    if (!isValid) {
      return {
        ...formattedSettings,
        isValid: false,
      };
    }
  }

  return formattedSettings;
};

export default validateAndFormat;
