import { NavigationNode } from '../../../../types';
import { DEFAULT_AMOUNT } from '../../../common/constants';
import { SportLineRow } from './useColumns';

interface ConvertNodeToSportLineRowParams {
  node: NavigationNode;
  parentRow: SportLineRow | null;
}

const convertNodeToSportLineRow = (
  params: ConvertNodeToSportLineRowParams,
): SportLineRow | null => {
  const { node, parentRow } = params;

  if (node.type === 'ROOT') return null;

  const sportLineRow: SportLineRow = {
    id: node.id,
    isEnabled: !node.config?.is_disabled,
    disabledBy: node.config?.disabled_by,
    playerId: node.config?.player_id,
    priority: node.config?.priority || DEFAULT_AMOUNT,
    riskLevel: node.config?.risk_level || 'low',
    name: node.name,
    path: parentRow ? `${parentRow.path}/${node.id}` : node.id,
    type: node.type,
    itemId: node.id,
    parentRow,
  };

  return sportLineRow;
};

export default convertNodeToSportLineRow;
