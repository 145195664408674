import { GridApiPremium, UIContext } from '@miyagami-com/lsx-ui-components';
import { GridEventListener } from '@mui/x-data-grid-premium';
import { useCallback, useContext, useState } from 'react';
import convertSportLineRowToExchangeConfig from './convertSportLineRowToExchangeConfig';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { ExchangeConfig } from '../../../../types';
import { useIntl } from 'react-intl';
import messages from './messages';
import usePlatformForceCache, {
  ForceUpdateCacheParams,
} from '../../../common/hooks/usePlatformForceCache';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isInstanceOfGridCellEditCommitParams } from '../../../common/isInstanceOf';

interface UseOnCellEditCommitParams {
  playerId?: string;
  brandId: string;
  apiRef: React.MutableRefObject<GridApiPremium>;
}

type UpdateExchangeConfigParams = {
  exchangeConfig: Omit<ExchangeConfig, 'id' | 'disabled_by'>;
};

const useOnCellEditCommit = (
  hookParams: UseOnCellEditCommitParams,
): {
  isLoading: boolean;
  onCellEditCommit: GridEventListener<'cellEditCommit'>;
} => {
  const { playerId, brandId, apiRef } = hookParams;

  const [isLoading, setIsLoading] = useState(false);

  const firebase = useFirebaseApp();

  const forceCache = usePlatformForceCache({ brandId });

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onCellEditCommit: GridEventListener<'cellEditCommit'> = useCallback(
    async (params) => {
      if (isLoading) return;
      if (!isInstanceOfGridCellEditCommitParams(params)) {
        return;
      }
      setIsLoading(true);
      try {
        const { field, value } = params;

        const beforeUpdateRow = apiRef.current.getRow(params.id);

        const sportLineRow = {
          ...beforeUpdateRow,
          [field]: value,
        };

        const exchangeConfig = convertSportLineRowToExchangeConfig({
          sportLineRow,
          playerId,
          brandId,
        });

        const updateExchangeConfig = httpsCallable(
          functions,
          'back-exchangeConfig-updateExchangeConfig',
        );

        const updateExchangeConfigParams: UpdateExchangeConfigParams = {
          exchangeConfig,
        };

        await updateExchangeConfig(updateExchangeConfigParams);

        const forceExchangeConfig: ForceUpdateCacheParams = {
          type: 'exchangeConfig',
        };

        const forceNavigation: ForceUpdateCacheParams = {
          type: 'exchangeNavigationNode',
        };

        const cacheParams: ForceUpdateCacheParams[] = [
          forceExchangeConfig,
          forceNavigation,
        ];

        await forceCache(cacheParams);

        setAlert({
          message: intl.formatMessage(messages.successUpdate),
          show: true,
          severity: 'success',
        });
      } catch (error) {
        setAlert({
          message: intl.formatMessage(messages.errorUpdate),
          show: true,
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiRef,
      brandId,
      forceCache,
      functions,
      intl,
      playerId,
      setAlert,
      isLoading,
    ],
  );

  return { isLoading, onCellEditCommit };
};

export default useOnCellEditCommit;
