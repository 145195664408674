import {
  Box,
  Checkbox,
  CollapseBox,
  Divider,
  FormControlLabel,
  Grid,
  LoadingButton,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '../../Unknown/Select';
import { Formik } from 'formik';
import { Brand } from '../../../../types/supabase';
import generateAvailableOptions, {
  generateAvailableOptionsHalfs,
} from '../../../common/generateAvailableOptions';
import { DEFAULT_AMOUNT, MAX_PT_RATE } from '../../../common/constants';
import messages from '../BrandOverviewAddition/messages';
import {
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
} from '../../Unknown/Icons';
import { UserState } from '../../../../types';
import useNetworkTreeByBrand from '../../../common/hooks/useNetworkTreeByBrand';
import useUpdateBrandNetworkConfig from '../../../common/hooks/useUpdateBrandNetworkConfig';

type Params = {
  brand: Brand;
  authUser: UserState;
};

const BrandDetailNetworkUpdate = (params: Params): JSX.Element => {
  const { brand } = params;

  const intl = useIntl();

  const initialValues = {
    pt: brand.position_taking,
    commission: brand.commission,
    minPT: brand.brand_min_pt,
    maxPT: brand.brand_max_pt,
    extraPT: brand.brand_extra_pt,
    extraPTEnabled: brand.is_extra_pt_selected,
  };

  const { data: networkTree } = useNetworkTreeByBrand({ brandId: brand.id });
  const { mutate } = useUpdateBrandNetworkConfig({
    brand,
    enabled: !!networkTree,
  });

  const onUpdateNetworkConfig = (values: typeof initialValues): void => {
    mutate({
      payload: {
        ...values,
        commission: values.commission ?? 0,
        minPT: values.minPT ?? 0,
        extraPTEnabled: values.extraPTEnabled ?? false,
        extraPT: values.extraPTEnabled ? values.extraPT : 0,
        pt: values.extraPTEnabled ? 0 : values.pt,
      },
      networkTree: networkTree ?? undefined,
    });
  };

  const availablePTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  const availableCommissionOptions = generateAvailableOptions(
    DEFAULT_AMOUNT,
    0.1,
    0.001,
    3,
  );

  const availableMinPTOptions = generateAvailableOptionsHalfs(
    DEFAULT_AMOUNT,
    MAX_PT_RATE,
    0.005,
  );

  return (
    <CollapseBox
      defaultValue
      label={intl.formatMessage(messages.networkConfig)}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onUpdateNetworkConfig(values);
        }}
      >
        {({ handleChange, handleSubmit, values }): JSX.Element => {
          return (
            <Grid>
              <Grid paddingX={4} spacing={4} marginBottom={4}>
                <Grid item xs={12} marginTop={4}>
                  <Typography variant="h6">
                    {intl.formatMessage(messages.networkConfig)}
                  </Typography>
                </Grid>
                <Grid container pt={4} spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Select
                      menuItems={availablePTOptions}
                      name="pt"
                      defaultValue={initialValues.pt}
                      label={intl.formatMessage(messages.pt)}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      disabled={!!values.extraPTEnabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      menuItems={availableCommissionOptions}
                      name="commission"
                      defaultValue={initialValues.commission}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage(messages.commission)}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      menuItems={availableMinPTOptions}
                      name="minPT"
                      defaultValue={initialValues.minPT}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage(messages.brandMinPT)}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      menuItems={availableMinPTOptions}
                      name="maxPT"
                      defaultValue={initialValues.maxPT}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage(messages.brandMaxPT)}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      menuItems={availableMinPTOptions}
                      name="extraPT"
                      defaultValue={initialValues.extraPT}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage(messages.brandExtraPT)}
                      onChange={handleChange}
                      disabled={!values.extraPTEnabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" py={1.5}>
                      <FormControlLabel
                        label={intl.formatMessage(messages.extraPTEnabled)}
                        control={
                          <Checkbox
                            name="extraPTEnabled"
                            checked={!!values.extraPTEnabled}
                            defaultChecked={!!initialValues.extraPTEnabled}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <RadioButtonCheckedIcon color="primary" />
                            }
                            onChange={() => {
                              handleChange({
                                target: {
                                  name: 'extraPTEnabled',
                                  value: !values.extraPTEnabled,
                                },
                              });
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Box p={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Box>
            </Grid>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default BrandDetailNetworkUpdate;
