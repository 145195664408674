interface MapItToNameParams {
  eventName: string;
  selectionName: string;
}

interface MapIdToNameResult {
  selectionId: string;
  other: string;
}

const mapIdToName = (params: MapItToNameParams): MapIdToNameResult => {
  const { eventName, selectionName } = params;

  const splitter = eventName.includes(' @ ') ? ' @ ' : ' v ';
  const runners = eventName.split(splitter);

  return {
    selectionId: selectionName,
    other: runners.find((runner) => runner !== selectionName) || '',
  };
};

export default mapIdToName;
