import useSupabase from './useSupabase';
import { TelegramSettings } from './useGetTelegramSettings';

const useUpdateTelegramSettings = (): ((
  settings: TelegramSettings[],
) => Promise<void>) => {
  const supabase = useSupabase();

  const updateSettings = async (settings: TelegramSettings[]) => {
    const { error } = await supabase
      .from('brand_telegram_settings')
      .upsert(settings, { onConflict: 'id' });

    if (error) {
      console.error('Error while updating settings:', error);
      return;
    }
  };

  return updateSettings;
};

export default useUpdateTelegramSettings;
