import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';

import {
  BackOfficeUserStatus,
  ExtendedPlayer,
  RiskLevel,
} from '../../../../types';
import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import usePlayerStats from '../../../common/hooks/usePlayerStats';

import UserDetailPageLayout from '../../User/UserDetailPageLayout';
import PlayerExchangePage from '../../PlayerExchange/PlayerExchangePage';
import UserLimitsPage from '../../User/UserLimitPage';
import UserBalancePage from '../../User/UserBalancePage';

import PlayerAccountPage from '../PlayerAccountPage';
import PlayerNetworkPage from '../PlayerNetworkPage';
import PlayerSecurityPage from '../PlayerSecurityPage';

import messages from './messages';
import DefaultLayout from '../../Unknown/DefaultLayout';
import LoadingBox from '../../Unknown/LoadingBox';
import usePlayer from '../../../common/hooks/usePlayer';
import useBrand from '../../../common/hooks/useBrand';
import PlayerCurrencyPage from '../PlayerCurrencyPage';
import UserTransactionsPage from '../../User/UserTransactionsPage';
import usePlayerTransactionStats from '../../../common/hooks/usePlayerTransactionStats';

type QueryParams = {
  brandId: string;
  userId: string;
  userTab: string;
  exchangeTab: string;
};

const PlayerDetailPage: React.FC = () => {
  const intl = useIntl();
  const params: QueryParams = useParams();
  const { brandId, userId } = params;
  const { data: stats } = usePlayerStats({ playerId: userId });

  const { data: player, isLoading } = usePlayer<ExtendedPlayer>({
    playerId: userId,
    select: '*, playerDetail:player_detail(*)',
  });

  const { data: brand, isLoading: isLoadingBrand } = useBrand({ brandId });

  const { data: transactionStats } = usePlayerTransactionStats({
    playerId: userId,
    brandId,
  });
  const { withdrawTotal, depositTotal } = transactionStats || {};
  const username = useMemo(() => player?.username || '', [player]);

  const playerCreatedAtDate = new Date(player?.created_at || 0);

  const userCreatedAt = format(
    playerCreatedAtDate.getTime(),
    DEFAULT_DATE_FORMAT,
  );

  const defaultLayoutProps = useMemo(
    () => ({
      path: [
        { label: intl.formatMessage(messages.playersAndAgents) },
        {
          label: intl.formatMessage(messages.playerOverview),
          url: `/b/${brandId}/player-overview`,
        },
        { label: username },
      ],
    }),
    [brandId, intl, username],
  );

  if (isLoadingBrand || isLoading) {
    return (
      <DefaultLayout {...defaultLayoutProps}>
        <LoadingBox />
      </DefaultLayout>
    );
  }

  if (!brand) {
    return <Redirect to={`/b/${brandId}/player-overview`} />;
  }

  const tabList = [
    {
      label: intl.formatMessage(messages.accountLabel),
      value: 'account',
      component: <PlayerAccountPage player={player} />,
    },
    {
      label: intl.formatMessage(messages.networkLabel),
      value: 'network',
      component: player ? (
        <PlayerNetworkPage brand={brand} player={player} />
      ) : null,
    },
    {
      label: intl.formatMessage(messages.balanceLabel),
      value: 'balance',
      component: <UserBalancePage type="player" />,
    },
    {
      label: intl.formatMessage(messages.exchangeLabel),
      value: 'exchange',
      component: <PlayerExchangePage params={params} />,
    },
    {
      label: intl.formatMessage(messages.securityLabel),
      value: 'security',
      component: <PlayerSecurityPage playerId={userId} />,
    },
    {
      label: intl.formatMessage(messages.limitsLabel),
      value: 'limits',
      component: <UserLimitsPage type="player" />,
    },
    {
      label: intl.formatMessage(messages.currencyLabel),
      value: 'currency',
      component: <PlayerCurrencyPage playerId={userId} brandId={brandId} />,
    },
    {
      label: intl.formatMessage(messages.transactionsLabel),
      value: 'transactions',
      component: <UserTransactionsPage />,
    },
  ];

  const playerStatus = (player?.status || 'inactive') as BackOfficeUserStatus;

  const playerRiskLevel = player?.playerDetail?.risk_level as RiskLevel;

  return (
    <UserDetailPageLayout
      headerProps={{
        username,
        brandId,
        userId,
        riskLevel: playerRiskLevel,
        role: intl.formatMessage(messages.player),
        status: playerStatus,
        userCreatedAt: userCreatedAt || '',
        lastActivityTime: '',
        stats,
        withdrawTotal,
        depositTotal,
      }}
      defaultLayoutProps={defaultLayoutProps}
      accountTabsProps={{ tabs: tabList, type: 'player' }}
    />
  );
};

export default PlayerDetailPage;
