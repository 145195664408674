import React, { FC, ReactNode, useContext } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  IconButton,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import RefreshIcon from '@mui/icons-material/Refresh';

import DetailsInformationGridItem from '../DetailsInformationGridItem';
import useBreakpoints from '../../../common/hooks/useBreakpoints';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

type TextDataProps = {
  mainText: string | ReactNode;
  secondText?: string | ReactNode;
};

type StatusDataProps = {
  statusColor: string;
  statusText: string | ReactNode;
  secondText?: string | ReactNode;
};

type DepositAndWithdrawDataProps = {
  widthdrawTotal?: {
    message: string;
    value: number;
  };
  depositTotal?: {
    message: string;
    value: number;
  };
};

type DetailsInformationHeaderProps = {
  data: {
    left: TextDataProps;
    centerLeft?: TextDataProps;
    centerRight: TextDataProps;
    right: StatusDataProps;
    userId?: string;
    brandId?: string;
    role?: string;
    depositAndWithdrawData?: DepositAndWithdrawDataProps;
  };
};

const DetailsInformationHeader: FC<DetailsInformationHeaderProps> = ({
  data,
  children,
}) => {
  const { isMobile, isTablet } = useBreakpoints();
  const {
    left,
    centerLeft,
    right,
    role,
    brandId,
    userId,
    depositAndWithdrawData,
  } = data;

  let { centerRight } = data;
  centerRight = {
    ...centerRight,
    mainText: (centerRight.mainText as string)?.replace('€', ''),
    secondText:
      typeof centerRight.secondText === 'string'
        ? (centerRight.secondText as string).replace('€', '')
        : centerRight.secondText,
  };

  const [isLoadingStats, setIsLoadingStats] = React.useState(false);

  const firebase = useFirebaseApp();

  const { setAlert } = useContext(UIContext);

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const isPlayer = role === 'Player';

  const handleUpdatePlayer = async () => {
    setIsLoadingStats(true);

    try {
      if (!isPlayer) throw new Error('User is not a player');

      setAlert({
        show: true,
        severity: 'info',
        message: 'Updating player stats this may take a few seconds...',
      });
      if (!userId || !brandId) {
        throw new Error('Missing userId or brandId');
      }
      const updatePlayerStats = httpsCallable(
        functions,
        'back-exchangeBet-callUpdateExposure',
      );

      await updatePlayerStats({
        playerId: userId,
        brandId,
      });

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setAlert({
        show: true,
        severity: 'error',
        message: error?.message,
      });
    }

    setIsLoadingStats(false);
  };

  const delimiterLine = (
    <Box width={1} height="1px" m={3} bgcolor="text.disabled" />
  );
  const StatsHeader = () => {
    return (
      <Box display="flex">
        <IconButton onClick={handleUpdatePlayer} disabled={isLoadingStats}>
          <RefreshIcon />
        </IconButton>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4">Balance {centerRight.mainText}</Typography>

          {centerRight.secondText && isPlayer && (
            <Box display="flex">
              <Typography variant="h3">
                Exposure {centerRight.secondText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Paper>
      <Grid container spacing={0} py={3} alignItems="center">
        <DetailsInformationGridItem isBorderRight={!isMobile}>
          <Typography variant="h4">{left.mainText}</Typography>
          <Typography variant="h3">{left.secondText}</Typography>
        </DetailsInformationGridItem>
        {isMobile && delimiterLine}
        <DetailsInformationGridItem isBorderRight={!isMobile && !isTablet}>
          <Typography variant="h4">{centerLeft?.mainText}</Typography>
          <Typography variant="h3">{centerLeft?.secondText}</Typography>
        </DetailsInformationGridItem>
        {(isTablet || isMobile) && delimiterLine}
        <DetailsInformationGridItem isBorderRight={!isMobile}>
          <StatsHeader />
        </DetailsInformationGridItem>
        {isMobile && delimiterLine}
        <DetailsInformationGridItem isBorderRight={!isMobile}>
          <Box display="flex" alignItems="center">
            <Box
              width={14}
              height={14}
              borderRadius="50%"
              bgcolor={right.statusColor}
              mr={1}
            />
            <Typography variant="h4">{right.statusText}</Typography>
          </Box>
          <Typography variant="h3">{right.secondText}</Typography>
        </DetailsInformationGridItem>
        {isMobile && delimiterLine}
        <DetailsInformationGridItem>
          {depositAndWithdrawData?.widthdrawTotal && (
            <Typography>
              {depositAndWithdrawData.widthdrawTotal.message}:
              {depositAndWithdrawData.widthdrawTotal.value}
            </Typography>
          )}
          {depositAndWithdrawData?.depositTotal && (
            <Typography>
              {depositAndWithdrawData.depositTotal.message}:
              {depositAndWithdrawData.depositTotal.value}
            </Typography>
          )}
        </DetailsInformationGridItem>
      </Grid>
      {children}
    </Paper>
  );
};

export default DetailsInformationHeader;
