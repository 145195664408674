import { Form } from 'formik';
import { Box, Grid, TextField } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import messages from './messages';

export interface TelegramSettings {
  id: string;
  chatId: string;
  minRange: number;
  maxRange: number;
}

interface TelegramFormProps {
  index: number;
  values: TelegramSettings;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const TelegramSettingsForm = (props: TelegramFormProps): JSX.Element => {
  const { index, values, handleChange, handleBlur } = props;
  const { id, chatId, minRange, maxRange } = values;

  const intl = useIntl();

  return (
    <Form>
      <Grid container px={3} spacing={4}>
        <TextField
          name={`settings.${index}.id`}
          value={id}
          type="hidden"
          variant="standard"
        />
        <Grid item xs={6}>
          <Box pt={4} pb={2}>
            <TextField
              name={`settings.${index}.chatId`}
              label={intl.formatMessage(messages.chatId)}
              value={chatId}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pt={4} pb={2} display="flex" gap={2}>
            <TextField
              name={`settings.${index}.minRange`}
              label={intl.formatMessage(messages.minRange)}
              value={minRange}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth
            />
            <TextField
              name={`settings.${index}.maxRange`}
              label={intl.formatMessage(messages.maxRange)}
              value={maxRange}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

export default TelegramSettingsForm;
