import { useAuth } from 'reactfire';
import DefaultLayout from '../Unknown/DefaultLayout';
import OverviewLayout from '../Unknown/OverviewLayout';
import DataGridTable from '../Unknown/DataGridTable';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../common/constants';
import { useState } from 'react';
import useGetFundPassingBets from '../../common/hooks/useGetFundPassingBets';
import BetSnapshotModal from './BetSnapshotModal';
import useColumns from './useColumns';
import useBrandId from '../../common/hooks/useBrandId';

const RiskBetsPage = (): JSX.Element => {
  const path = [{ label: 'Risk bets' }, { label: 'Risk bets' }];

  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[5]);
  const [modalOpen, setModalOpen] = useState(false);

  const [betId, setBetId] = useState(null);
  const [betType, setBetType] = useState('');

  const [marketName, setMarketName] = useState('');
  const [selectionId, setSelectionId] = useState(0);
  const [selectionName, setSelectionName] = useState('');
  const [eventOpenDateTime, setEventOpenDateTime] = useState('');

  const auth = useAuth();
  const user = auth.currentUser;
  const userId = user?.uid || '';

  const brandId = useBrandId();

  const { data, isLoading } = useGetFundPassingBets({
    uid: userId,
    brandId,
  });

  const rows = data?.data || [];
  const defaultRows = rows;

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const columns = useColumns({
    setModalOpen,
    setBetId,
    setMarketName,
    setSelectionId,
    setSelectionName,
    setEventOpenDateTime,
    setBetType,
  });

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: 'Risk bets',
          defaultRows: defaultRows,
        }}
      >
        <DataGridTable
          rows={rows}
          columns={columns}
          tableKey="risk-bets"
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          disableSelectionOnClick
          autoHeight
          rowHeight={35}
          headerHeight={35}
          loading={isLoading}
        />
        {betId && (
          <BetSnapshotModal
            isOpen={modalOpen}
            onClose={onCloseModal}
            betId={betId}
            marketName={marketName}
            selectionId={selectionId}
            selectionName={selectionName}
            eventOpenDateTime={eventOpenDateTime}
            betType={betType}
          />
        )}
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default RiskBetsPage;
