import { useContext, useEffect, useState } from 'react';
import {
  Box,
  CollapseBox,
  Divider,
  Grid,
  LoadingButton,
  Typography,
  UIContext,
} from '@miyagami-com/lsx-ui-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import messages from './messages';
import useBrandId from '../../../common/hooks/useBrandId';
import useGetTelegramSettings from '../../../common/hooks/useGetTelegramSettings';
import TelegramSettingsForm, { TelegramSettings } from './TelegramSettingsForm';
import useUpdateTelegramSettings from '../../../common/hooks/useUpdateTelegramSettings';
import validateAndFormat, { formatForForm } from './validateAndFormat';

const EMPTY_ROW = {
  id: '',
  chatId: ' ',
  minRange: 0,
  maxRange: 0,
};

const BrandTelegramSettings = (): JSX.Element => {
  const brandId = useBrandId();

  const intl = useIntl();
  const { setAlert } = useContext(UIContext);

  const [settings, setSettings] = useState<TelegramSettings[]>([EMPTY_ROW]);
  const { data } = useGetTelegramSettings(brandId);
  const updateSettings = useUpdateTelegramSettings();

  useEffect(() => {
    if (data?.length) {
      const formattedSettings = data.map((set) => formatForForm(set));
      setSettings(formattedSettings);
    }
  }, [data]);

  const onUpdateSettings = (values: { settings: TelegramSettings[] }) => {
    try {
      const formValues = values.settings;
      const updatedSettings = formValues.map((set) => {
        return validateAndFormat(set, brandId);
      });

      if (updatedSettings.some((set) => !set.isValid)) {
        setAlert({
          show: true,
          severity: 'error',
          message: intl.formatMessage(messages.invalidRange),
        });
        return;
      }

      const sanitisedSettings = updatedSettings.map(
        ({ isValid, ...rest }) => rest,
      );
      updateSettings(sanitisedSettings);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdate),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.errorUpdate),
      });
    }
  };

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.telegramSettings)}
    >
      <Formik
        initialValues={{ settings }}
        onSubmit={onUpdateSettings}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => {
          const formValues = values.settings;
          const addFormRow = () => {
            setFieldValue('settings', [...formValues, EMPTY_ROW]);
          };

          return (
            <Form>
              <Grid container pl={3} pr={3} pt={3} spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.setChatId} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    <FormattedMessage {...messages.setBetRanges} />
                  </Typography>
                </Grid>
              </Grid>
              <Box pb={2}>
                {formValues.map((_setting, idx) => (
                  <TelegramSettingsForm
                    key={idx}
                    index={idx}
                    values={formValues[idx]}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                ))}
              </Box>
              <Box pb={2} pl={3}>
                <AddCircleOutlineIcon cursor="pointer" onClick={addFormRow} />
              </Box>
              <Divider />
              <Box p={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage {...messages.save} />
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </CollapseBox>
  );
};

export default BrandTelegramSettings;
