import { GridRenderCellParams } from '@miyagami-com/lsx-ui-components';
import { useGridApiContext } from '@mui/x-data-grid';
import CheckBoxCell from '../CheckBoxCell';

interface Props extends GridRenderCellParams<boolean> {
  disabled?: boolean;
}

function IsDisabledCell(props: Props): JSX.Element {
  const { value, id, field, disabled = false } = props;

  const apiRef = useGridApiContext();

  const onClickEnabledCell = () => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: !value,
    });

    apiRef.current.commitCellChange({ id, field });
  };

  return (
    <CheckBoxCell
      checkboxProps={{ checked: value, onClick: onClickEnabledCell }}
      label=""
      disabled={disabled}
    />
  );
}

export default IsDisabledCell;
