import { defineMessages } from 'react-intl';

export default defineMessages({
  telegramSettings: {
    id: 'Brand.BrandTelegram.telegramSettings',
    defaultMessage: 'Telegram Settings',
  },
  setChatId: {
    id: 'Brand.BrandTelegram.setChatId',
    defaultMessage: 'Telegram channel ID',
  },
  chatId: {
    id: 'Brand.BrandTelegram.chatId',
    defaultMessage: 'Channel id',
  },
  setBetRanges: {
    id: 'Brand.BrandTelegram.setBetRanges',
    defaultMessage: 'Bet ranges',
  },
  minRange: {
    id: 'Brand.BrandTelegram.minRange',
    defaultMessage: 'Min',
  },
  maxRange: {
    id: 'Brand.BrandTelegram.maxRange',
    defaultMessage: 'Max',
  },
  save: {
    id: 'Brand.BrandTelegram.save',
    defaultMessage: 'Save',
  },
  successUpdate: {
    id: 'Brand.BrandTelegram.successUpdate',
    defaultMessage: 'Telegram settings succesfully updated!',
  },
  errorUpdate: {
    id: 'Brand.BrandTelegram.errorUpdate',
    defaultMessage: 'An error occurred while updating Telegram settings',
  },
  invalidRange: {
    id: 'Brand.BrandTelegram.invalidRange',
    defaultMessage: 'Fix invalid Min-Max range to proceed',
  },
});
