import format from 'date-fns/format';
import { DEFAULT_DATE_TIME_FORMAT } from '../../common/constants';

interface FormatDataProps {
  selectionName: string;
  eventName: string;
  competitionName: string | null;
  eventOpenDateTime: string;
}

interface FormatDataResult {
  formattedSelectionName: string;
  formattedEventName: string;
  formattedEventDateTime: string;
}

const formatData = (props: FormatDataProps): FormatDataResult => {
  const { selectionName, eventName, competitionName, eventOpenDateTime } =
    props;

  const formattedSelectionName = selectionName.replace(/^\d+\.\s*/, '');

  const formattedEventName = `${eventName} ${
    competitionName ? `(${competitionName})` : ''
  }`;

  const formattedEventDateTime = format(
    new Date(eventOpenDateTime),
    DEFAULT_DATE_TIME_FORMAT,
  );

  return { formattedSelectionName, formattedEventName, formattedEventDateTime };
};

export default formatData;
