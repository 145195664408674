import { Grid } from '@miyagami-com/lsx-ui-components';
import SnapshotTable from './SnapshotTable';

interface DefaultSnaphotParams {
  snapshot: {
    availableToBack: { price: number; size: number }[];
    availableToLay: { price: number; size: number }[];
  };
}

const DefaultMarketSnapshot = (params: DefaultSnaphotParams): JSX.Element => {
  const { snapshot } = params;

  return (
    <>
      <Grid item xs={12} md={6}>
        <SnapshotTable snapshotData={snapshot.availableToBack} side="Back" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SnapshotTable snapshotData={snapshot.availableToLay} side="Lay" />
      </Grid>
    </>
  );
};

export default DefaultMarketSnapshot;
