import { defineMessages } from 'react-intl';

export default defineMessages({
  availableData: {
    id: 'Unknown.DefaultSnapshot.availableData',
    defaultMessage: 'Available to {side}',
  },
  price: {
    id: 'Unknown.DefaultSnapshot.price',
    defaultMessage: 'Price',
  },
  size: {
    id: 'Unknown.DefaultSnapshot.size',
    defaultMessage: 'Size',
  },
});
