import axios, { AxiosInstance } from 'axios';
import { useCookies } from 'react-cookie';
import { SESSION_KEY } from '../constants';

const playerPlatformApiURL = process.env.REACT_APP_PLAYER_PLATFORM_URL;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getApiURL = (brandId: string): string => {
  return `https://${brandId}.${playerPlatformApiURL}/api`;
};

const usePlayerPlatformApiClient = (brandId: string): AxiosInstance => {
  const url = getApiURL(brandId);

  const [cookies] = useCookies([SESSION_KEY]);

  return axios.create({
    baseURL: url,
    headers: {
      'x-auth': cookies[SESSION_KEY],
    },
    withCredentials: true,
  });
};

export default usePlayerPlatformApiClient;
