import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  scrollableBox: {
    width: '100%',
    height: '30rem',
    overflowY: 'scroll',
  },
  backBox: {
    backgroundColor: '#A6D8FE',
  },
  layBox: {
    backgroundColor: '#F9C8D0',
  },
  tableRow: {
    '& > td': {
      height: '2.5rem',
      border: '1px solid #E0E0E0',
      textAlign: 'center',
      '& > div': {
        fontWeight: 'bold',
      },
    },
  },
  titleCell: {
    fontWeight: 'bold',
    textAlign: 'left',
    '&': {
      textAlign: 'left !important',
    },
  },
}));

export default useStyles;
