import { defineMessages } from 'react-intl';

export default defineMessages({
  transactions: {
    id: 'Transaction.TransactionsOverview.transactions',
    defaultMessage: 'Transactions',
  },
  errorPaginationMessage: {
    id: 'Transaction.TransactionsOverview.errorPaginationMessage',
    defaultMessage: 'An error occurred while getting transactions.',
  },
  system: {
    id: 'Transaction.TransactionsOverview.system',
    defaultMessage: 'System',
  },
  id: {
    id: 'Transaction.TransactionsList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'Transaction.TransactionsList.date',
    defaultMessage: 'Date',
  },
  origin: {
    id: 'Transaction.TransactionsList.origin',
    defaultMessage: 'Origin',
  },
  participant: {
    id: 'Transaction.TransactionsList.participant',
    defaultMessage: 'Participant',
  },
  exposure: {
    id: 'Transaction.TransactionsList.exposure',
    defaultMessage: 'Exposure',
  },
  balance: {
    id: 'Transaction.TransactionsList.balance',
    defaultMessage: 'Balance',
  },
  amount: {
    id: 'Transaction.TransactionsList.amount',
    defaultMessage: 'Amount',
  },
  note: {
    id: 'Transaction.TransactionsList.note',
    defaultMessage: 'Note',
  },
} as const);
