import {
  Box,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@miyagami-com/lsx-ui-components';
import useStyles from './useStyles';
import { BetSnapshot } from '../useBetSnapshot';
import PriceSizeCell from './PriceSizeCell';
import mapIdToName from './mapIdToName';

interface HandicapSnaphotParams {
  runnersSnapshot: BetSnapshot[];
  eventName: string;
  selectionId: number;
  selectionName: string;
}

const HandicapMarketSnapshot = (params: HandicapSnaphotParams): JSX.Element => {
  const { runnersSnapshot, eventName, selectionId, selectionName } = params;

  const classes = useStyles();
  const selectionNameMap = mapIdToName({ eventName, selectionName });

  return (
    <Grid item xs={12} md={12}>
      <TableContainer component={Paper}>
        <Box className={classes.scrollableBox}>
          <Table>
            {runnersSnapshot.map(
              ({ selectionId: runnerId, handicap, ex: snapshot }, idx) => (
                <TableRow key={idx} className={classes.tableRow}>
                  <TableCell className={classes.titleCell}>
                    {runnerId == selectionId
                      ? selectionNameMap.selectionId
                      : selectionNameMap.other}{' '}
                    {handicap}
                  </TableCell>
                  {[2, 1, 0].map((i) => (
                    <PriceSizeCell
                      key={`back-${i}`}
                      price={snapshot.availableToBack[i]?.price}
                      size={snapshot.availableToBack[i]?.size}
                      className={i === 0 ? classes.backBox : undefined}
                    />
                  ))}
                  {[0, 1, 2].map((i) => (
                    <PriceSizeCell
                      key={`lay-${i}`}
                      price={snapshot.availableToLay[i]?.price}
                      size={snapshot.availableToLay[i]?.size}
                      className={i === 0 ? classes.layBox : undefined}
                    />
                  ))}
                </TableRow>
              ),
            )}
          </Table>
        </Box>
      </TableContainer>
    </Grid>
  );
};

export default HandicapMarketSnapshot;
