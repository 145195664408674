import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  DataGridProps,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import FormattedData from '../../Unknown/FormattedData';

import messages from './messages';
import DataGridTable from '../../Unknown/DataGridTable';
// import Currency from '../../Unknown/Currency';

const propertiesColumn = {
  editable: false,
  filterable: false,
  flex: 0.5,
};

interface TransactionsDepositWithdrawListProps {
  dataGridProps: Omit<DataGridProps, 'columns'>;
}

const TransactionsDepositWithdrawList: FC<TransactionsDepositWithdrawListProps> =
  ({ dataGridProps }) => {
    const intl = useIntl();

    // const renderCurrency = ({ value }: GridRenderCellParams) => (
    //   <Currency value={value} />
    // );

    const columns: GridColumns = [
      {
        ...propertiesColumn,
        field: 'id',
        headerName: intl.formatMessage(messages.id),
      },
      {
        ...propertiesColumn,
        field: 'date',
        headerName: intl.formatMessage(messages.date),
        renderCell: ({ value }: GridRenderCellParams) => (
          <FormattedData dateValue={value} />
        ),
      },
      {
        ...propertiesColumn,
        field: 'username',
        headerName: intl.formatMessage(messages.origin),
      },
      {
        ...propertiesColumn,
        field: 'receiver',
        headerName: intl.formatMessage(messages.participant),
      },
      {
        ...propertiesColumn,
        field: 'amount',
        headerName: intl.formatMessage(messages.amount),
        // renderCell: renderCurrency,
      },
      {
        ...propertiesColumn,
        field: 'balance',
        headerName: intl.formatMessage(messages.balance),
        // renderCell: renderCurrency,
      },
      {
        ...propertiesColumn,
        field: 'exposure',
        headerName: intl.formatMessage(messages.exposure),
        // renderCell: renderCurrency,
      },
      {
        ...propertiesColumn,
        field: 'note',
        headerName: intl.formatMessage(messages.note),
      },
    ];

    return (
      <DataGridTable
        {...dataGridProps}
        tableKey="transactions"
        withoutPadding
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        disableSelectionOnClick={true}
        autoHeight={true}
        rowHeight={35}
        headerHeight={35}
        columns={columns}
        hideFooterRowCount
        pagination
      />
    );
  };

export default TransactionsDepositWithdrawList;
