import TransactionsDepositWidthdraw from '../../Transaction/TransactionsDepositWithdraw';
import { Grid } from '@miyagami-com/lsx-ui-components';
const UserTransactionsPage = (): JSX.Element => {
  return (
    <Grid container spacing={4} pt={4}>
      <Grid item xs={12}>
        <TransactionsDepositWidthdraw />
      </Grid>
    </Grid>
  );
};

export default UserTransactionsPage;
