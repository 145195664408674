import { useCallback, useEffect, useState } from 'react';
import useSupabase from '../../common/hooks/useSupabase';

export interface BetSnapshot {
  ex: {
    availableToBack: { price: number; size: number }[];
    availableToLay: { price: number; size: number }[];
  };
  totalMatched: number;
  selectionId: number;
  handicap?: number;
}

export interface AdditionalDetails {
  sportName: string;
  eventName: string;
  competitionName: string | null;
}

interface BetSnapshotResult {
  snapshotData: BetSnapshot | null;
  additionalDetails: AdditionalDetails | null;
  handicapSnapshot: BetSnapshot[];
}

const useBetSnapshot = (betId: number): BetSnapshotResult => {
  const supabase = useSupabase();

  const [snapshotData, setSnapshotData] = useState<BetSnapshot | null>(null);
  const [additionalDetails, setAdditionalDetails] =
    useState<AdditionalDetails | null>(null);

  const [handicapSnapshot, setHandicapSnapshot] = useState<BetSnapshot[]>([]);

  const fetchBetSnapshotAsync = useCallback(async () => {
    const { data: betSnapshotData, error } = await supabase
      .from('bet_snapshots')
      .select('*')
      .eq('bet_id', betId)
      .single();

    if (error) return null;

    if (betSnapshotData) {
      const { selection_id: betSelectionId, bet_runners: betRunnersRaw } =
        betSnapshotData;

      const betRunners = betRunnersRaw as unknown as BetSnapshot[];
      if (!betRunners) return null;

      const betRunner =
        betRunners.find(
          (runner: BetSnapshot) => runner.selectionId === betSelectionId,
        ) || null;

      setSnapshotData(betRunner);
      setHandicapSnapshot(betRunners);
    }
  }, [betId, supabase]);

  const fetchBetAdditionalDetailsAsync = useCallback(async () => {
    const { data: betDetailsData, error } = await supabase
      .from('betfair_exchange_bets')
      .select('event_type_name, event_name, competition_name')
      .eq('id', betId)
      .single();

    if (error) {
      return null;
    }

    if (betDetailsData) {
      const {
        event_type_name: sportName,
        event_name: eventName,
        competition_name: competitionName,
      } = betDetailsData;
      setAdditionalDetails({ sportName, eventName, competitionName });
    }
  }, [betId, supabase]);

  useEffect(() => {
    fetchBetSnapshotAsync();
    fetchBetAdditionalDetailsAsync();
  }, [fetchBetSnapshotAsync, fetchBetAdditionalDetailsAsync]);

  return { snapshotData, additionalDetails, handicapSnapshot };
};

export default useBetSnapshot;
