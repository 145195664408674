import { GridCellEditCommitParams } from '@mui/x-data-grid';

export const isInstanceOfGridCellEditCommitParams = (
  value: unknown,
): value is GridCellEditCommitParams => {
  if (!value || typeof value !== 'object') {
    return false;
  }
  const fields = ['id', 'field', 'value'];
  const keys = Object.keys(value);

  return (
    keys.length === fields.length && keys.every((key) => fields.includes(key))
  );
};
