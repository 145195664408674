import { User } from 'firebase/auth';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'reactfire';
import useSignIn from '../../../common/hooks/useSignIn';
import Big from 'big.js';
export interface AuthContextProps {
  firebaseUser: User | null;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

export const AuthContextProvider: React.FC = (props) => {
  const { children } = props;

  const firebaseAuth = useAuth();
  const serverSignIn = useSignIn();

  const [firebaseUser, setFirebaseUser] = useState<null | User>(null);
  const [isInit, setIsInit] = useState(true);

  firebaseAuth.onAuthStateChanged((user) => {
    setFirebaseUser(user);
  });

  const refreshSession = useCallback(async () => {
    if (firebaseUser && isInit) {
      const tokenResult = await firebaseUser?.getIdTokenResult();
      const tokenExp = new Date(tokenResult.expirationTime).getTime();
      if (Big(tokenExp).lt(new Date().getTime())) {
        await serverSignIn({
          idToken: tokenResult.token,
          isFirstLogin: false,
        });
      }
      setIsInit(false);
    }
  }, [firebaseUser, serverSignIn, isInit]);

  useEffect(() => {
    refreshSession();
  }, [firebaseUser, refreshSession]);

  return (
    <AuthContext.Provider value={{ firebaseUser }}>
      {children}
    </AuthContext.Provider>
  );
};
