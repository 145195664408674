import { useQuery } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION, DEFAULT_TRANSACTION_CHUNK_SIZE } from '../constants';
import { ExtendedTransaction } from '../../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UseUserDepositWithdrawTransactionsParams {
  userId: string;
  brandId: string;
}

const useUserDepositWithdrawTransactions = (
  params: UseUserDepositWithdrawTransactionsParams,
): typeof returnFunction => {
  const { userId, brandId } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);
  const getTransactionsDepositWidthdrawByUserId = httpsCallable(
    functions,
    'back-transaction-getTransactionsDepositWidthdrawByUserId',
  );

  const returnFunction = useQuery(
    ['userDepositWithdrawTransactions', userId],
    async () => {
      let offset = 0;
      const fetchedTransactions: ExtendedTransaction[] = [];
      let responseTransaction: ExtendedTransaction[] = [];

      do {
        const response = await getTransactionsDepositWidthdrawByUserId({
          userId,
          brandId,
          offset,
        });

        responseTransaction = (response.data || []) as ExtendedTransaction[];
        fetchedTransactions.push(...responseTransaction);
        offset += DEFAULT_TRANSACTION_CHUNK_SIZE;
      } while (responseTransaction.length > DEFAULT_TRANSACTION_CHUNK_SIZE - 1);

      return fetchedTransactions;
    },
  );

  return returnFunction;
};

export default useUserDepositWithdrawTransactions;
