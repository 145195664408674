import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BackOfficeUserStatus, RiskLevel } from '../../../../types';

import { RISK_LEVELS } from '../../../common/constants';

import useFormatCurrencyNumber from '../../../common/hooks/useFormatCurrencyNumber';
import useUserStatuses from '../../../common/hooks/useUserStatuses';
import useCheckBrandIsSuspend from '../../../common/hooks/useCheckBrandIsSuspend';

import DetailsInformationHeader from '../../Unknown/DetailsInformationHeader';

import getUserStatus from './getUserStatus';
import messages from './messages';
import { UserStat } from '../../../../types/supabase';

export interface UserAccountHeaderProps {
  username: string | null;
  userCreatedAt: string | null;
  riskLevel?: RiskLevel;
  role: string | null;
  userId?: string;
  brandId?: string;
  status: BackOfficeUserStatus;
  lastActivityTime: string | null;
  stats?: UserStat | null;
  withdrawTotal?: number;
  depositTotal?: number;
}

const UserAccountHeader: React.FC<UserAccountHeaderProps> = (params) => {
  const {
    children,
    username,
    userCreatedAt,
    userId,
    brandId,
    riskLevel,
    role,
    status,
    lastActivityTime,
    stats,
    withdrawTotal = 0,
    depositTotal = 0,
  } = params;

  const intl = useIntl();
  const userStatuses = useUserStatuses();

  const isBrandSuspended = useCheckBrandIsSuspend();

  const { label, color } = getUserStatus(
    userStatuses,
    status,
    isBrandSuspended,
  );

  const riskLevelLabel = useMemo(
    () => (riskLevel ? RISK_LEVELS[riskLevel].label : ''),
    [riskLevel],
  );

  const balance = useFormatCurrencyNumber({ value: stats?.balance });

  const exposure = useFormatCurrencyNumber({ value: stats?.exposure });

  const data = {
    userId,
    brandId,
    role: role || undefined,
    left: {
      mainText: username,
      secondText: intl.formatMessage(messages.createdAtLabel, {
        date: userCreatedAt,
      }),
    },
    centerLeft: {
      mainText: riskLevelLabel,
      secondText: intl.formatMessage(messages.roleLabel, { role }),
    },
    centerRight: {
      mainText: balance,
      secondText: exposure,
    },
    right: {
      statusColor: color,
      statusText: label,
      secondText: intl.formatMessage(messages.lastOnlineLabel, {
        time: lastActivityTime,
      }),
    },
    depositAndWithdrawData: {
      widthdrawTotal: {
        message: intl.formatMessage(messages.withdrawalLabel),
        value: withdrawTotal,
      },
      depositTotal: {
        message: intl.formatMessage(messages.depositLabel),
        value: depositTotal,
      },
    },
  };

  return (
    <DetailsInformationHeader data={data}>{children}</DetailsInformationHeader>
  );
};

export default UserAccountHeader;
