import { FC, useMemo, useState } from 'react';
import { CollapseBox } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../types';
import messages from './messages';
import getAllUsers from '../../../common/getAllUsers';
import useUserDepositWithdrawTransactions from '../../../common/hooks/useUserDepositWithdrawTransactions';
import { normalizeRows } from './normalizeRows';
import TransactionsDepositWithdrawList from './TransactionDepositWithdrawList';
type QueryParams = {
  brandId: string;
  userId: string;
};

interface TransactionsDepositWidthdrawProps {
  brandId?: string;
  userId?: string;
}

const TransactionsDepositWidthdraw: FC<TransactionsDepositWidthdrawProps> = (
  props,
) => {
  const { userId: initialUserId, brandId } = props;

  const intl = useIntl();

  const { brandId: paramsBrandId, userId: paramsUserId }: QueryParams =
    useParams();

  const userId = (paramsUserId || initialUserId) as string;

  const selectedBrandId = (paramsBrandId || brandId) as string;

  if (!userId || !selectedBrandId) {
    throw new Error('userId || selectedBrandId is undefined');
  }

  const { data: transactions, isLoading } = useUserDepositWithdrawTransactions({
    userId,
    brandId: selectedBrandId,
  });

  console.log({ transactions });

  const { users } = useSelector((state: RootState) => state.globalData);

  const rows = useMemo(() => {
    const allUsers = getAllUsers({ users, brandId: selectedBrandId });

    return normalizeRows({
      data: transactions,
      users: allUsers || [],
      userId,
      intl,
    });
  }, [intl, selectedBrandId, transactions, userId, users]);

  const [pageSize, setPageSize] = useState<number>(100);

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const dataGridProps = {
    loading: isLoading,
    rows,
    pageSize,
    onPageSizeChange,
  };

  return (
    <CollapseBox label={intl.formatMessage(messages.transactions)} defaultValue>
      <TransactionsDepositWithdrawList dataGridProps={dataGridProps} />
    </CollapseBox>
  );
};

export default TransactionsDepositWidthdraw;
