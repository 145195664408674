import { Box, TableCell } from '@miyagami-com/lsx-ui-components';

interface HandicapPriceProps {
  price?: number;
  size?: number;
  className?: string;
}

const PriceSizeCell = (props: HandicapPriceProps): JSX.Element => {
  const { price, size, className } = props;

  return (
    <TableCell className={className}>
      <Box>{price || ''}</Box>
      {size || ''}
    </TableCell>
  );
};

export default PriceSizeCell;
