import { UseMutationResult, useMutation } from 'react-query';
import { Brand } from '../../../types/supabase';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../constants';
import { TreeRow } from '../../components/Network/NetworkTreeList/createTreeRow';
import { useContext } from 'react';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import messages from '../../components/Brand/BrandOverviewAddition/messages';
import { useIntl } from 'react-intl';
import { getFunctions, httpsCallable } from 'firebase/functions';

type Params = {
  brand: Brand;
  enabled?: boolean;
};

type MutateParams = {
  payload: Payload;
  networkTree?: TreeRow[][];
};

type Payload = {
  pt: number;
  commission: number;
  minPT: number | null;
  maxPT: number | null;
  extraPT: number | null;
  extraPTEnabled: boolean | null;
};

const useUpdateBrandNetworkConfig = (
  params: Params,
): UseMutationResult<boolean, { message: string }, MutateParams, unknown> => {
  const { brand } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  return useMutation(
    ['brand', 'networkConfig', brand.id],
    async (mutateParams: MutateParams) => {
      const { networkTree, payload } = mutateParams;

      if (!networkTree) {
        setAlert({
          show: true,
          message: 'Network tree is not available',
          severity: 'error',
        });
        return false;
      }

      const updateBrandNetworkConfig = httpsCallable(
        functions,
        'back-brand-updateBrandNetworkConfig',
      );

      await updateBrandNetworkConfig({
        brandOwnerId: brand.owner_id,
        brandId: brand.id,
        payload: {
          position_taking: payload.pt,
          commission: payload.commission,
          brand_min_pt: payload.minPT,
          brand_max_pt: payload.maxPT,
          brand_extra_pt: payload.extraPT,
          is_extra_pt_selected: payload.extraPTEnabled,
        },
        networkTree: networkTree,
      });

      return true;
    },
    {
      onError: (error) => {
        const err = error as { message: string };
        setAlert({
          show: true,
          severity: 'error',
          message: err.message,
        });
      },
      onSuccess: () => {
        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(messages.updateNetworkConfigSuccess),
        });
      },
    },
  );
};

export default useUpdateBrandNetworkConfig;
